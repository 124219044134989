<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-24 lg:px-8 lg:py-10"
      >
        <div class="grid">
          <div
            class="bg-gradient-to-r shadow rounded-lg ring ring-green-800 ring-offset-2"
            style="background-color: #272827"
          >
            <div class="m-4 text-white">
              <h3 class="text-sm font-bold">{{ dataModel.brand }}</h3>

              <h2
                class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                {{ dataModel.model }}
              </h2>
            </div>

            <!-- <center>
              <v-calendar :attributes="attrs" />
            </center> -->

            <div class="text-gray-500" style="margin: 30px">
              <div
                class="grid grid-cols-1 gap-4 lg:grid-cols-1 mt-2 cursor-pointer"
              >
                <hr style="border-top: 1px solid" />
                <div class="">
                  <div class="grid grid-cols-5 gap-1 lg:grid-cols-5">
                    <div class="col-span-4 flex flex-col justify-center">
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        Booking Date
                      </a>
                      <p style="font-weight: 600; font-size: 12px">
                        Select Date
                      </p>
                    </div>
                    <div>
                      <div class="flex justify-center mt-3">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="border-top: 1px solid" />
              </div>

              <div
                class="grid grid-cols-1 gap-4 lg:grid-cols-1 mt-2 cursor-pointer"
              >
                <div class="">
                  <div class="grid grid-cols-5 gap-1 lg:grid-cols-5">
                    <div class="col-span-4 flex flex-col justify-center">
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        Delivery & Pick Up Time
                      </a>
                      <p style="font-weight: 600; font-size: 12px">
                        Select your time
                      </p>
                    </div>
                    <div>
                      <div class="flex justify-center mt-3">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </div>
                  <div class=""></div>
                  ddyvhhgf
                </div>
                <hr style="border-top: 1px solid" />
              </div>

              <div
                class="grid grid-cols-1 gap-4 lg:grid-cols-1 mt-2 cursor-pointer"
              >
                <div class="">
                  <div class="grid grid-cols-5 gap-1 lg:grid-cols-5">
                    <div class="col-span-4 flex flex-col justify-center">
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        Delivery Address
                      </a>
                      <p style="font-weight: 600; font-size: 12px">
                        Select your address
                      </p>
                    </div>
                    <div>
                      <div class="flex justify-center mt-3">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="border-top: 1px solid" />
              </div>

              <div
                class="grid grid-cols-1 gap-4 lg:grid-cols-1 mt-2 cursor-pointer"
              >
                <div class="">
                  <div class="grid grid-cols-5 gap-1 lg:grid-cols-5">
                    <div class="col-span-4 flex flex-col justify-center">
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        Voucher Code
                      </a>
                      <p style="font-weight: 600; font-size: 12px">
                        Add Voucher Code
                      </p>
                    </div>
                    <div>
                      <div class="flex justify-center mt-3">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style="border-top: 1px solid" />
              </div>

              <div class="grid grid-cols-1 gap-4 lg:grid-cols-1 mt-2">
                <div class="">
                  <div class="grid grid-cols-5 gap-1 lg:grid-cols-5">
                    <div class="col-span-5 flex flex-col justify-center">
                      <a
                        class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                      >
                        Booking Price
                      </a>
                      <br />

                      <div class="grid grid-cols-2 lg:grid-cols-2">
                        <div>
                          <p style="font-size: 12px">Your plan</p>
                        </div>
                        <div class="flex justify-end">
                          <a
                            class="text-md l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-green-500"
                            style="font-size: 14px"
                          >
                            1 Week
                          </a>
                        </div>
                      </div>

                      <div class="grid grid-cols-2 lg:grid-cols-2 mt-1">
                        <div>
                          <p style="font-size: 12px">Start</p>
                        </div>
                        <div class="flex justify-end">
                          <a
                            class="text-md l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-green-500"
                            style="font-size: 14px"
                          >
                            10 Jan 2023
                          </a>
                        </div>
                      </div>

                      <div class="grid grid-cols-2 lg:grid-cols-2 mt-1">
                        <div>
                          <p style="font-size: 12px">End</p>
                        </div>
                        <div class="flex justify-end">
                          <a
                            class="text-md l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-green-500"
                            style="font-size: 14px"
                          >
                            23 Jan 2023
                          </a>
                        </div>
                      </div>

                      <br />

                      <hr style="border-top: 1px solid" />

                      <br />

                      <div class="grid grid-cols-2 lg:grid-cols-2 mt-1">
                        <div>
                          <p style="font-size: 12px">Total</p>
                        </div>
                        <div class="flex justify-end">
                          <p
                            class="text-2xl font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-green-500"
                          >
                            33,234
                          </p>
                        </div>
                      </div>
                      <div class="flex justify-end">
                        <p style="font-size: 12px">THB / Week</p>
                      </div>

                      <br />

                      <hr style="border-top: 1px solid" />

                      <div class="mt-4">
                        <input
                          class="mr-2 leading-tight"
                          value="true"
                          type="checkbox"
                        />

                        I've read and agreed
                        <a style="color: green" href="#">
                          Terms and Conditions for VOLTZ</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <center>
              <button
                class="w-42 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
              >
                จ่ายเงิน
              </button>
            </center>
            <br />
          </div>
        </div>
      </div>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";

import transactions from "./transactions_bet.vue";

import car from "@/api/car";

import { API_URL } from "../../config";

import axios from "axios";

import { POOL_ADDRESS, VERSION } from "../../config";

export default {
  data() {
    return {
      version: VERSION,
      cars: [],
      model: this.$route.params.model,
      dataModel: {},
      attrs: [
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: { start: new Date(2022, 0, 14), end: new Date(2022, 0, 18) },
        },
      ],
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    transactions,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    axios.get(API_URL + "/carDetail/" + this.model).then((response) => {
      this.dataModel = response.data.car;
    });
  },
};
</script>
